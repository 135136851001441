import React from 'react'
import Posts from './Posts';

const PostsHome = () => {
  return (
        <Posts />

)
}

export default PostsHome;