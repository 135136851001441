import Thumbnail1 from '../Components/Blogs/assets/blog25.jpg'


export const DUMMY_POSTS = [
    {
        id: '1',
        thumbnail: Thumbnail1,
        category: 'finance',
        title: 'This is the title of first',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
    },
    {
        id: '2',
        thumbnail: Thumbnail1,
        category: 'finance and assets',
        title: 'This is the title of second',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
       
    },
    {
        id: '3',
        thumbnail: Thumbnail1,
        category: 'finance apsnmdas and assets',
        title: 'This is the title of third',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
    },
    {
        id: '4',
        thumbnail: Thumbnail1,
        category: 'finance apsnmdas and assets',
        title: 'This is the title of four',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
    },
    {
        id: '5',
        thumbnail: Thumbnail1,
        category: 'finance apsnmdas and assets',
        title: 'This is the title of Five',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
    },
    {
        id: '6',
        thumbnail: Thumbnail1,
        category: 'finance apsnmdas and assets',
        title: 'This is the title of Six',
        desc: 'lansdniasdnioansdoinasdnoansdonad'
    },
];



export const DETAILED_DUMMY_POSTS =[
    {

    }
];